<template>
  <v-card max-height="100%">
    <v-card-text>
      <v-progress-linear
        indeterminate
        color="success"
        v-show="getEventLoading"
      ></v-progress-linear>
      <v-row no-gutters ref="linhaColunas">
        <v-col cols="3" class="mr-1">
          <!-- @click.ctrl="dialogAll" -->
          <!-- @click.stop="$store.dispatch('loadEventos')" -->
          <Colunas
            :title="`Não Atendidos (${getEventos.length})`"
            :events="getEventos"
            :btn="true"
            @eventSelected="selEvt"
            @refresh="refreshEvents"
            @multi="dialogAll"
          />
        </v-col>
        <v-col>
          <Atendimento
            v-model="event"
            @next="nextEvent($event)"
            @previous="previousEvent($event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Dialog Evento Unico -->
    <v-dialog v-model="getTratamento" width="500">
      <v-card>
        <v-card-title> Fechar Evento </v-card-title>

        <v-card-text>
          <v-row v-show="!evtToClose.real">
            <v-col>
              <v-btn color="error" block @click="evtToClose.real = 2">
                <v-icon class="mr-2">mdi-thumb-down</v-icon> Disparo Falso
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="success" block @click="evtToClose.real = 1">
                <v-icon class="mr-2">mdi-thumb-up</v-icon> Disparo Real
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-show="evtToClose.real">
            <v-col>
              <v-label>
                Disparo considerado :
                {{ evtToClose.real == 1 ? " Real" : " Falso" }}
              </v-label>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="evtToClose.tratamento"
                counter="1000"
                rows="2"
                label="Tratamento"
                hint="Tratamento se necessário"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" text @click="closeDialog"> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="closeEvent">
            Fechar Evento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FIM Dialog Evento Unico -->
    <!-- Dialog Evento Multiplo -->
    <v-dialog v-model="disparoMultiplos" width="500">
      <v-card>
        <v-card-title>
          Fechar {{ evtsToClose.ids.length }} Eventos ?</v-card-title
        >

        <v-card-text>
          <v-row v-show="!evtsToClose.real">
            <v-col>
              <v-btn color="error" block @click="evtsToClose.real = 2">
                <v-icon class="mr-2">mdi-thumb-down</v-icon> Disparos Falsos
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="success" block @click="evtsToClose.real = 1">
                <v-icon class="mr-2">mdi-thumb-up</v-icon> Disparos Reais
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-show="evtsToClose.real">
            <v-col>
              <v-label>
                Disparos considerado :
                {{ evtsToClose.real == 1 ? " Real" : " Falso" }}
              </v-label>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="evtsToClose.tratamento"
                counter="1000"
                rows="2"
                label="Tratamento"
                hint="Tratamento se necessário"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" text @click="disparoMultiplos = false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="closeEvents">
            Fechar Evento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- card que mostra a limpeza dos dados -->
    <v-expand-transition>
    <v-card width="500" class="cardFlutuante blue-grey darken-1" v-show="newEvent.id > 0" >
      <v-card-title><v-row>
        <v-col>Novo evento</v-col>
        <v-col cols="auto">
            <v-btn icon x-small @click="newEvent = {}"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
        </v-row> </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-img :src="newEvent.snapshot" contain></v-img>
          </v-col>
          <v-col>
           <h4>{{ newEvent.cameraName }}</h4> 
           <h4>{{ newEvent.evtName || newEvent.code }}</h4> 
           <h4>{{ newEvent.clientName }}</h4> 
           <h5>{{ new Date(newEvent.createdAt).toLocaleString() }}</h5> 
           <v-btn small text @click="refreshEvents">Atualizar eventos</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import Colunas from "../components/Eventos/EventoColuna.vue";
import Atendimento from "../components/Eventos/Atendimento.vue";
import { mapGetters } from "vuex";
export default {
  name: "Monitoramento",
  data: () => {
    return {
      event: {},
      newEvent: {},
      evtToClose: { real: 0 },
      evtsToClose: { real: 0, ids: [] },
      disparoMultiplos: false,
      ws: null,
      timeout: null,
    };
  },
  components: {
    Colunas,
    Atendimento,
  },
  computed: {
    ...mapGetters(["getEventos", "getEventLoading", "getEvento"]),
    getTratamento: {
      get() {
        return this.$store.getters.getTratamento;
      },
      set() {},
    },
  },
  methods: {
    selEvt(event) {
      this.event = event;
    },
    getNewEvent(evtId){
      this.$http(`eventos/${evtId}`).then(async (resp) => {
        this.newEvent = resp.data;
        this.newEvent.snapshot = this.newEvent.snapshot + this.$store.getters.getImgTokenQuery;
        if(this.timeout){
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.newEvent = {};
        }, 3000);
      });
    },
    closeDialog() {
      this.$store.dispatch("setTratamento", null);
      this.evtToClose = { real: 0 };
      this.$store.dispatch("setEvento", {});
      this.refreshEvents();
    },
    closeEvent() {
      let payload = {
        ...this.getTratamento,
        ...this.evtToClose,
        cb: this.closeDialog,
      };
      this.$store.dispatch("tratamento", payload);
    },
    dialogAll() {
      this.disparoMultiplos = true;
      this.evtsToClose.ids = this.getEventos.map((evt) => evt.id);
    },
    closeEvents() {
      // console.log("closeEvents", this.evtsToClose);
      this.$store.dispatch("tratamentoMultiplo", {
        ...this.evtsToClose,
        cb: () => {
          this.disparoMultiplos = false;
          this.evtsToClose = { real: 0, ids: [] };
          this.closeDialog();
        },
      });
    },
    onResize() {
      let windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      this.$store.dispatch("setWindowSize", windowSize);
    },
    refreshEvents() {
      // eventos por cliente
      if (this.$route.query.clientId) {
        this.$store.dispatch("loadEventosByClientId", {
          id: this.$route.query.clientId,
        });
        // eventos por camera
      } else if (this.$route.query.cameraId) {
        this.$store.dispatch("loadEventosByCameraId", {
          id: this.$route.query.cameraId,
        });
        // todos os eventos
      } else {
        this.$store.dispatch("loadEventos");
      }
    },
    nextEvent(actual) {
      let idx = this.getEventos.findIndex((evt) => evt.id == actual);
      if (idx + 1 < this.getEventos.length) {
        this.$store.dispatch("setEvento", this.getEventos[idx + 1]);
      }
    },
    previousEvent(actual) {
      let idx = this.getEventos.findIndex((evt) => evt.id == actual);
      if (idx - 1 >= 0) {
        this.$store.dispatch("setEvento", this.getEventos[idx - 1]);
      }
    },
    updateEvent(event) {
      let e = JSON.parse(event.data);
      if(Array.isArray(e.eventId)){
        // this.$store.dispatch("appendEventById", e.eventId);
        this.getNewEvent(e.eventId);
      }
    },
    startWebSocket() {
      console.log("start websocket");
      this.wsUrl = "";
      if (process.env.NODE_ENV === "production") {
        this.wsUrl =
          `${window.location.protocol}//${window.location.host}/api/events/`
            .replace("http", "ws")
            .replace("https", "wss");
      } else {
        this.wsUrl = `${this.$http.defaults.baseURL}events/`
          .replace("http", "ws")
          .replace("https", "wss");
      }
      this.wsUrl += this.$store.getters.getImgTokenQuery;
      this.ws = new WebSocket(this.wsUrl);
      this.ws.onmessage = this.updateEvent;
      this.ws.onclose = () => {
        console.log("ws closed");
        this.disconectWebSocket();
        setTimeout(() => {
          this.startWebSocket();
        }, 5000);
      };
    },
    disconectWebSocket() {
      this.ws.removeEventListener("message", this.updateEvent);
      this.ws.onclose = () => {};
      this.ws.close();
    },
  },
  mounted() {
    this.refreshEvents();
    this.$store.dispatch("setEvento", {});
    this.$vuetify.goTo(this.$refs.linhaColunas, {
      duration: 300,
      offset: 10,
      easing: "easeInOutCubic",
    });
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.startWebSocket();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  beforeRouteLeave(to, from, next) {
    if (this.ws) {
      console.log("close websocket");
      this.ws.removeEventListener("message", this.updateEvent);
      this.ws.onclose = () => {};
      this.ws.close();
    }
    next();
  },
};
</script>
<style scoped>
.cardFlutuante {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
</style>