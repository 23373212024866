<template>
  <v-card :color="event.evtColor || '#ff00ff'" class="pt-1" width="98%">
    <v-card
      width="100%"
      outlined
      @click="$store.dispatch('setEvento', event)"
      class="rounded-tr-xl px-1"
    >
      <!-- linha do nome do evento -->
      <div class="row ma-0 pa-0">
        <div
          class="col-12 text-truncate text-subtitle-1 font-weight-bold ma-0 pa-0 ml-1"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"
                ><div class="row ma-0 pa-0">
                  <div class="col-12 text-truncate ma-0 pa-0 ml-0">
                    {{ event.evtName || `Não Cadastrado ${event.code}` }}
                  </div>
                </div>
              </span>
            </template>
            <span> {{ event.evtName || event.code }}</span>
          </v-tooltip>
        </div>
      </div>
      <!-- linha da data -->
      <v-row no-gutters>
        <v-col>Data: {{ new Date(event.createdAt).toLocaleString() }}</v-col>
      </v-row>
      <!-- linha do nome da camera -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"
            ><div class="row ma-0 pa-0">
              <div class="col-12 text-truncate ma-0 pa-0 ml-0">
                Cam: {{ event.cameraId }} -
                <span class="font-weight-bold">{{ event.cameraName }}</span>
              </div>
            </div>
          </span>
        </template>
        <span> {{ event.cameraId }} - {{ event.cameraName }}</span>
      </v-tooltip>
      <!-- linha canal zona -->
      <v-row no-gutters class="ma-0 pa-0">
        <v-col>Canal: {{ event.channel }}</v-col>
        <v-col>Zona: {{ event.zone }}</v-col>
      </v-row>
      <!-- linha nome do cliente -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on"
            ><div class="row ma-0 pa-0">
              <div class="col-12 text-truncate ma-0 pa-0 ml-0">
                Cliente: {{ event.clientName }}
              </div>
            </div>
          </span>
        </template>
        <span> {{ event.clientName }}</span>
      </v-tooltip>
      <!-- linha do botão -->
      <v-btn
        :disabled="getEvento.id != event.id"
        block
        small
        text
        color="warning"
        @click="$store.dispatch('setTratamento', event)"
      >
        Fechar Evento
      </v-btn>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "eventCard",
  props: {
    event: {
      type: Object,
    },
    btn: {
      type: Boolean,
    },
  },
  data: () => ({
    color: "warning",
    speed: 0,
    speedLimit: 0,
  }),
  methods: {},
  computed: {
    ...mapGetters(["getEvento"]),
  },
};
</script>

<style scoped>
#badge {
  width: 100% !important;
}
</style>