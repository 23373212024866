<template >
  <v-card ref="card" class="cinza" width="100%" max-width="100%">
    <v-toolbar flat color="primary" dark dense>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- menu de opções -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" x-small icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$emit('refresh')">
            <v-list-item-title>Atualizar eventos</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('multi')">
            <v-list-item-title>Tratar todos eventos</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <!-- <v-list
      flat
      width="100%"
      max-height="100%"
      nav
      class="cinza over m-0 p-0"
      dense
      expand
    >
      <v-list-item-group color="primary">
        <v-list-item v-for="item in events" :key="item.eventId">
          
          <EVTCARD
            :event="item"
            v-if="$store.getters.getWindowSize.width > 865"
          />
          <EVTCARDM :event="item" v-else />
        </v-list-item>
      </v-list-item-group>
    </v-list> -->
    <v-virtual-scroll
      :bench="3"
      :items="events"
      :height="getWindowSize.height * 0.8"
      item-height="165"
    >
      <template v-slot:default="{ item }">
        <EVTCARD
          :event="item"
          v-if="$store.getters.getWindowSize.width > 865"
          class="ma-1"
        />
        <EVTCARDM :event="item" v-else class="ma-1" />
      </template>
    </v-virtual-scroll>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import EVTCARD from "../Eventos/EventoCard.vue";
import EVTCARDM from "../Eventos/EventoCardM.vue";
export default {
  name: "colunasEvento",
  data: () => ({
    altura: 0,
    grouped: false,
  }),
  components: {
    EVTCARD,
    EVTCARDM,
  },
  props: {
    title: {
      type: String,
    },
    events: {},
    btn: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["getLoadingEvents", "getWindowSize"]),
  },
  methods: {
    teste(value) {
      // console.log(value);
      this.$store.dispatch("attendEventGrouped", value);
    },
  },
  // mounted(){
  //   let aa = this.$refs.card.clientHeight
  //   console.log(this.$refs.card.clientHeight);
  //   // this.altura = parseInt(aa) * 0.9
  //   console.log(aa);
  // }
};
</script>

<style scoped>
.cinza {
  background-color: #c2c0c0 !important;
  color: rgb(73, 73, 73) !important;
}
.v-list--nav .v-list-item {
  margin: 1px !important;
  padding: 0 3px !important;
}
.v-list--nav {
  margin: 0px !important;
  padding: 0px !important;
}
.over {
  overflow: auto;
  height: 80vh;
}

* {
  scrollbar-width: thin;
  scrollbar-color: gray rgb(82, 82, 82);
}
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: rgb(161, 161, 161);
}
*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid rgb(82, 82, 82);
}
</style>