var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pt-1",attrs:{"color":_vm.event.evtColor || '#ff00ff',"width":"98%"}},[_c('v-card',{staticClass:"rounded-tr-xl px-1",attrs:{"width":"100%","outlined":""},on:{"click":function($event){return _vm.$store.dispatch('setEvento', _vm.event)}}},[_c('div',{staticClass:"row ma-0 pa-0"},[_c('div',{staticClass:"col-12 text-truncate text-subtitle-1 font-weight-bold ma-0 pa-0 ml-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('div',{staticClass:"row ma-0 pa-0"},[_c('div',{staticClass:"col-12 text-truncate ma-0 pa-0 ml-0"},[_vm._v(" "+_vm._s(_vm.event.evtName || ("Não Cadastrado " + (_vm.event.code)))+" ")])])])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.event.evtName || _vm.event.code))])])],1)]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v("Data: "+_vm._s(new Date(_vm.event.createdAt).toLocaleString()))])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('div',{staticClass:"row ma-0 pa-0"},[_c('div',{staticClass:"col-12 text-truncate ma-0 pa-0 ml-0"},[_vm._v(" Cam: "+_vm._s(_vm.event.cameraId)+" - "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.event.cameraName))])])])])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.event.cameraId)+" - "+_vm._s(_vm.event.cameraName))])]),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"no-gutters":""}},[_c('v-col',[_vm._v("Canal: "+_vm._s(_vm.event.channel))]),_c('v-col',[_vm._v("Zona: "+_vm._s(_vm.event.zone))])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('div',{staticClass:"row ma-0 pa-0"},[_c('div',{staticClass:"col-12 text-truncate ma-0 pa-0 ml-0"},[_vm._v(" Cliente: "+_vm._s(_vm.event.clientName)+" ")])])])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.event.clientName))])]),_c('v-btn',{attrs:{"disabled":_vm.getEvento.id != _vm.event.id,"block":"","small":"","text":"","color":"warning"},on:{"click":function($event){return _vm.$store.dispatch('setTratamento', _vm.event)}}},[_vm._v(" Fechar Evento ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }