<template>
  <v-card :color="event.evtColor || '#ff00ff'" class="pt-1" width="98%">
    <v-card
      width="100%"
      outlined
      @click="$store.dispatch('setEvento', event)"
      class="rounded-tr-xl px-1"
    >
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="text-truncate text-subtitle-1">{{ event.evtName || `Não Cadastrado ${event.code}` }}</v-list-item-title>
        <v-list-item-subtitle class="text-truncate">
          {{ new Date(event.createdAt).toLocaleString() }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-truncate">
          Zona: {{ event.zone }} - {{event.cameraName}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
      <v-btn
        v-show="getEvento.id == event.id"
        block
        small
        text
        color="warning"
        @click="$store.dispatch('setTratamento', event)"
      >
        Fechar Evento
      </v-btn>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "eventCard",
  props: {
    event: {
      type: Object,
    },
    btn: {
      type: Boolean,
    },
  },
  data: () => ({
    color: "warning",
    speed: 0,
    speedLimit: 0,
  }),
  methods: {},
  computed: {
    ...mapGetters(["getEvento"]),
  },
};
</script>

<style scoped>
#badge {
  width: 100% !important;
}
</style>